<template>
  <div class="partner">
    <a
      @click="() => sendInfo(data.id)"
      class="partner__link"
      :href="data.link"
      target="_blank"
    ></a>
    <div class="partner__item-content">
      <p v-if="data.isFeatured" class="partner__offer">Займ по 0%</p>
      <div class="partner__item-top">
        <div class="partner__logo">
          <img :src="data.logo" :alt="data.title" />
        </div>
        <div class="partner__info-block">
          <div class="partner__sum-block">
            <p class="partner__sum">
              <span>до</span> {{ data.header }} <span>₽</span>
            </p>
          </div>
        </div>
      </div>

      <p class="partner__desc">
        {{ data.subHeader }}
      </p>
      <Button class="partner__action" withoutIcon> Получить </Button>
      <p class="partner__desc partner__ads" v-html="linkValue"></p>
    </div>
  </div>
</template>

<script>
import "./partner.scss";
import finalStat from "@/api/finalStat";

export default {
  name: "Partner",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    sendInfo(id) {
      try {
        finalStat({
          id,
        });
      } catch (e) {
        console.log("cant sent stat");
      }
    },
    makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
  },
  computed: {
    linkValue() {
      const linkParams = new URL(this.data.link).searchParams;
      const param = linkParams.get("erid")
        ? linkParams.get("erid").split("?")[0]
        : "LjN8K" + this.makeid(4);

      return `Реклама ${this.data.org} <br> Erid: ${param}`;
    },
  },
};
</script>
